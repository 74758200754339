import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'home',
    },
  },
  {
    name: 'home',
    path: '/',
    component: () => import('./view/home'),
    meta: {
      title: '熏聞成種-首页',
    },
  },
  {
    name: 'search',
    path: '/search',
    component: () => import('@/view/home/search'),
    meta: {
      title: '熏聞成種-搜索',
    },
  },
  {
    name: 'redirect',
    path: '/u/:id',
    component: () => import('@/view/redirect/index'),
    meta: {
      title: '跳转',
    },
  },
  {
	name: 'Video',
    path: '/video',
    redirect: '/video/list/0',
    meta: {
      title: '影音',
    },
    children: [
				{
				  path: 'list/:tabIndex?',
				  name: 'VideoList',
				  component: () => import('@/view/video/index'),
				  meta: { title: '专辑列表' },
				  children: [
					{
					  path: ':sub0ID?',
					  name: 'VideoParentID',
					  component: () => import('@/view/video/index'), // Consider using a different component if navigation depth changes significantly
					  children: [
						{
						  path: ':sub1ID?',
						  name: 'VideoSub1',
						  component: () => import('@/view/video/index'),
						  children: [
							{
							  path: ':sub2ID?',
							  name: 'VideoSub2',
							  component: () => import('@/view/video/index'),
							  children: [
								{
								  path: ':sub3ID?',
								  name: 'VideoSub3',
								  component: () => import('@/view/video/index'),
								  children: [
									{
									  path: ':sub4ID?',
									  name: 'VideoSub4',
									  component: () => import('@/view/video/index'),
									  children: [
										{
										  path: ':time',
										  name: 'VideoListTime',
										  component: () => import('@/view/video/index'),
										  meta: { title: '专辑列表'}
										}
									  ]
									}
								  ]
								}
							  ]
							}
						  ]
						}
					  ]
					}
				  ]
				},
{
  path: 'player/:model/:aid',
  component: () => import('@/view/video/player'), // This serves as the parent route component
  meta: { title: '播放器' },
  children: [
    // Root player route - directly accessing a player without specific IDs
    {
      path: '',
      name: 'VideoPlayerRoot',
      component: () => import('@/view/video/player'),
      meta: { title: '播放器' },
    },
    // Detail player route including optional parameters for different ID levels
    {
      path: ':id?/:time?/:sub0ID?/:sub1ID?/:sub2ID?/:sub3ID?/:sub4ID?',
      name: 'VideoPlayerDetail',
      component: () => import('@/view/video/player'),
      meta: { title: '播放器' },
    }
  ]
}

    ]
  },
  {
    name: 'Article',
    path: '/article',
    redirect: '/article/list/0',
    meta: {
      title: '资讯',
    },
    children: [
      {
        path: 'list/:aid',
        name: 'ArticleList',
        component: () => import('@/view/article/index'),
        meta: { title: '列表'}
      },
      {
        path: 'detail/:id',
        name: 'ArticleDetailID',
        component: () => import('@/view/article/detail'),
        meta: { title: '详情'},
        children: [
          {
            path: ':time',
            name: 'ArticleDetailTime',
            component: () => import('@/view/article/detail'),
            meta: { title: '详情'},
          },
        ]
      },
      {
        path: 'timetable',
        name: 'TimeTable',
        component: () => import('@/view/article/timetable'),
        meta: { title: '详情'},
        children: [
          {
            path: ':time',
            name: 'TimeTableTime',
            component: () => import('@/view/article/timetable'),
            meta: { title: '详情'},
          },
        ]
      },
	  {
        path: 'info',
        name: 'Info',
        component: () => import('@/view/article/info'),
        meta: { title: '信息'},
        children: [
          {
            path: ':id',
            name: 'infoID',
            component: () => import('@/view/article/info'),
            meta: { title: '信息'},
          },
        ]
      },
    ]
  }
];
export { routes };

const router = createRouter({
  mode: "history",
  routes,
  history: createWebHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  
    const metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute('content', to.meta.description || '上成下德法師 學習分享平台；俗名：蔡禮旭老師');
  } else {

    const meta = document.createElement('meta');
    meta.name = 'description';
    meta.content = to.meta.description || '上成下德法師 學習分享平台；俗名：蔡禮旭老師';
    document.head.appendChild(meta);
  }
  next();
});

export { router };
